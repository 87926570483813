// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/codebuild/output/src110722742/src/joan-ribo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-cas-js": () => import("/codebuild/output/src110722742/src/joan-ribo/src/pages/index.cas.js" /* webpackChunkName: "component---src-pages-index-cas-js" */),
  "component---src-pages-index-val-js": () => import("/codebuild/output/src110722742/src/joan-ribo/src/pages/index.val.js" /* webpackChunkName: "component---src-pages-index-val-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src110722742/src/joan-ribo/.cache/data.json")

